/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
				$('#menu-huvudmeny li').each(function() {
					$(this).addClass('nav-item');
				});
				
				$('#menu-huvudmeny a').each(function() {
					$(this).addClass('nav-link');
				});
				
				$('[data-toggle="tooltip"]').tooltip({html:true});
				
				$(function() {
					$('a[href*="#"]:not([href="#"])').click(function() {
					if (location.pathname.replace(/^\//,'') === this.pathname.replace(/^\//,'') && location.hostname === this.hostname) {
						var target = $(this.hash);
						var header_height = $('.banner').outerHeight();
						target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
						if (target.length) {
						$('html,body').animate({ scrollTop: (target.offset().top) - header_height }, 200);
						return false;
						}
					}
					});
				});

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
				$('.logos').slick({
					lazyLoad: 'ondemand',
					dots: false,
					arrows: false,
					slidesToShow: 6,
					slidesToScroll: 6,
					autoplay: true,
					autoplaySpeed: 1500,
					infinite: true,
					responsive: [
						{
							breakpoint: 800,
							settings: {
								slidesToShow: 3,
								slidesToScroll: 3
							}
						},
						// You can unslick at a given breakpoint now by adding:
						// settings: "unslick"
						// instead of a settings object
					]
				});
				
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
							
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    'page_template_template_auktion': {
      init: function() {
				
				function format(num){
						var n = num.toString(), p = n.indexOf('.');
						return n.replace(/\d(?=(?:\d{3})+(?:\.|$))/g, function($0, i){
								return p<0 || i<p ? ($0+'.') : $0;
						});
				}
				
        // JavaScript to be fired on the auktion page
				var totalValue = 0;
				$(".item-value").each(function() {
					var itemValue = $(this).data("item-value");
					totalValue = totalValue + parseFloat(itemValue);
				});
				
				$("#totalValue").text(format(totalValue));
				
      }
    },
	  'page_template_template_bidragsgivare': {
      init: function() {
				
				var totalSoldValue = 0;
				$( ".item-sold-value" ).each(function() {
					var itemSoldValue = $(this).data("item-sold-value");
					totalSoldValue = totalSoldValue + parseFloat(itemSoldValue);
				});
				
				function format(num){
					var n = num.toString(), p = n.indexOf('.');
					return n.replace(/\d(?=(?:\d{3})+(?:\.|$))/g, function($0, i){
							return p<0 || i<p ? ($0+'.') : $0;
					});
				}
				
				$( "#totalSoldValue" ).text(format(totalSoldValue));
				
      }
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
